import React from "react"
import { graphql } from "gatsby"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"

function PrivacyPolicyPage(props) {
  let page = props.data.allUniquePagesJson.edges[0].node

  return (
    <Layout language="en" path={props.path}>
      <SEO title={page.metaTitle} description={page.metaDescription} />

      <Section className="joshua-tree-content privacy-policy-page">
        <Container>
          <h1>{page.heading}</h1>
          <MarkdownViewer markdown={page.body} />
        </Container>
      </Section>
    </Layout>
  )
}

export default PrivacyPolicyPage

export const pageQuery = graphql`
  query privacyQuery {
    allUniquePagesJson(filter: { title: { eq: "/privacy-policy/" } }) {
      edges {
        node {
          heading
          metaDescription
          metaTitle
          name
          body
          title
        }
      }
    }
  }
`
